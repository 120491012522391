<template>
  <div class="my-layout">
    <div class="box1">
      <div class="box1-left">
        <div class="box1-left-title">关于我们</div>
        <div class="box1-left-subtitle">值得信赖的高新技术企业</div>
        <div class="box1-left-content">
          新疆智翔科技有限公司成立于2010年，注册资金2000万元，是国家级高新技术企业，自治区级“专精特新”企业，乌鲁木齐物联网工程技术研究中心，目前拥有国家级和自治区级涉密信息系统资质以及各类知识产权50余项，先后获得国家科技部和自治区科技厅中小企业创新基金立项，获得自治区经信委电子发展专项和自治区重点新技术的资金支持。紧紧围绕新疆会议精神和数字中国战略，结合企业自主创新的发展战略，以云计算、人工智能、区块链、5G融合等技术在数字政务、数字医疗等方面的研发应用为发展方向，拥有一支近60人的产品研发和技术实施骨干团队。
        </div>
      </div>
    </div>
    <div class="box box2">
      <div class="box2-frame">
        <div class="title">经营管理关键行动</div>
        <div class="content">

          <VueSlickCarousel v-bind="box2Settings" style="width: 100%">
            <div v-for="(item,index) in box2Content" :key="index">
              <div class="content-item">
                <div class="content-item__header" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>
                <div class="content-item__body">
                  <div class="content-item__body-title">{{item.title}}</div>
                  <div class="content-item__body-content">{{item.content}}</div>
                </div>
              </div>
            </div>
            <template #customPaging="page">
              <div class="custom-dot">
                {{ page }}
              </div>
            </template>
          </VueSlickCarousel>
        </div>
      </div>

    </div>
    <div class="box3">
      <a-row>
        <a-col span="10">
          <div class="box3-left">
            企业文化
          </div>
        </a-col>
        <a-col span="14">
          <div class="box3-right">
            <a-row :gutter="90">
              <a-col :span="12" v-for="item in box3Content" :key="item.title">
                <div class="box3-right-item">
                  <div class="box3-right-item__title">{{item.title}}</div>
                  <div class="box3-right-item__content">{{item.content}}</div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="box box4">
      <div class="title">企业优势</div>
      <div class="content box4-content">
        <a-row :gutter="30">
          <a-col :span="8" v-for="item in box4Content" :key="item.title">
            <div class="box4-content-item">
              <div class="box4-content-item__header" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${item.img}'`}"></div>
              <div class="box4-content-item__body">
                <div class="box4-content-item__body-title">{{item.title}}</div>
                <div class="box4-content-item__body-content">{{item.content}}</div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="box box5">
      <div class="warp">
        <div class="title">合作伙伴</div>
        <div class="content">
          <VueSlickCarousel v-bind="settings" style="width: 100%">
            <div v-for="(item, index) in box5Content" :key="index">
              <div class="content-item">
                <div class="content-item__line"></div>
                <img :src="item" alt="" style="width: 100px;height: 52px"/>
              </div>
            </div>
            <template #customPaging="page">
              <div class="custom-dot">
                {{ page }}
              </div>
            </template>
          </VueSlickCarousel>
        </div>
      </div>

    </div>

    <div class="bottom-bg"></div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: "HomeIndex",
  components: {VueSlickCarousel},
  data(){
    return{
      box2Settings:{
        "autoplay": true,
        "autoplaySpeed": 3000,
        "speed": 1500,
        "arrows": false,
        "dots": true,
        "focusOnSelect": true,
        "pauseOnDotsHover": true,
        "pauseOnFocus": true,
        "pauseOnHover": true,
        "infinite": true,
        "slidesToShow": 4,
        "slidesToScroll": 1,
        "touchThreshold": 5,
        dotsClass: 'slick-dots custom-dot-class'
      },
      box2Content:[
        {
          img: "2d269152-2ca9-49f9-a679-ce74a87eb3bf.png",
          title: "科技运营管理效能",
          content: "运用大数据、云计算、区块链、人工智能及5G融合等新兴技术打造数字医疗大数据平台"
        },
        {
          img: "c3e466d8-4ebb-4b16-8cae-849f7bfd1140.png",
          title: "专业服务支持渠道",
          content: "通过人工智能、智慧视觉、数字加密等成熟技术为数据治理提供便利服务"
        },
        {
          img: "8cfe0810-855f-42cc-959f-15bd764a1668.png",
          title: "客户导向提升体验",
          content: "通过调研、访谈等方式收集客户反馈意见及需求；通过PDCA流程工具对服务进行持续性优化提升"
        },
        {
          img: "5e10721f-1d78-4504-b872-7fae64b3e20b.png",
          title: "赋能员工鼓励发展",
          content: "以人为本，通过工作带教、教练式辅导确保技术团队迅速成长；通过绩效考核、团队建设、人才储备进行梯队建设"
        },
        {
          img: "c8a051565e894e06ac42ecef8935a0cd.png",
          title: "资源共享增强实力",
          content: "关注政策风向、业界动态及最新技术应用；通过同行业交流，保持信息敏锐度，为技术创新收集信息并积累经验"
        }
      ],
      box3Content:[
        {
          title: "企业愿景",
          content: "成为最优秀的数字化服务商"
        },
        {
          title: "企业使命",
          content: "用科技为客户连接温暖的服务"
        },
        {
          title: "服务目标",
          content: "提升数字化治理能力，提高数字化服务水平"
        },
        {
          title: "宣传口号",
          content: "智翔科技-数字化服务商"
        }
      ],
      box4Content:[
        {
          img: "0b5a7cfe-c365-4d44-9b52-8ba0a7005d11.png",
          title: "定制服务",
          content: "为企业、行业客户提供量身定制功能开发服务，采用专业开发流程满足客户对软件、平台产品的不同功能定制。从客户需求出发深度整合各类业务需求，以用户体验为核心，定制专属私有化功能，延展触发用户粘性。"
        },
        {
          img: "4c9eedb5-50cb-4fc0-aec5-97a205651ef5.png",
          title: "售后服务",
            content: "智翔科技把客户对服务的满意度作为服务水平的监督指标，公司建立了完善的售后服务体系，质保期和运维期内提供7*24小时客服保障服务，使售后问题在承诺的SLA之内快速有效解决，提高客户服务满意度。"
        },
        {
          img: "ac6fa11c-8d1e-4811-aa59-15000a1343ce.png",
          title: "人才优势",
          content: "智翔科技坚信“人才是企业的第一生产力\"。公司一直致力于搭建一支具有战斗力的人才梯队，吸引行业高精尖人才加入，同时注重企业文化建设和完善公司制度，让每一位智翔人在公司拥有充分的发展空间。"
        }
      ],
      settings:{
        "autoplay": true,
        "autoplaySpeed": 3000,
        "speed": 1500,
        "arrows": false,
        "dots": true,
        "focusOnSelect": true,
        "pauseOnDotsHover": true,
        "pauseOnFocus": true,
        "pauseOnHover": true,
        "infinite": true,
        "slidesToShow": 5,
        "slidesToScroll": 1,
        "touchThreshold": 5,
        dotsClass: 'slick-dots custom-dot-class'
      },
      box5Content:[
          "https://minio.xjiot.link/93album/6e02e927-958a-4af2-91fd-fedf8a5e9aaf.png",
          "https://minio.xjiot.link/93album/1344a0af-aa25-4171-a6e6-d2bb30e1f2c6.png",
          "https://minio.xjiot.link/93album/fc0c7a1e-ec74-4426-b0c1-59e731b43e66.png",
          "https://minio.xjiot.link/93album/234639e3-3386-4e19-b416-ff7255f1477e.png",
          "https://minio.xjiot.link/93album/5cb32518-a1b6-4bff-9f18-6715473a4e92.png",
          "https://minio.xjiot.link/93album/0c11dce9-b47d-4835-9257-77b556ff646b.png",
          "https://minio.xjiot.link/93album/00798689-0b5d-4e1c-959c-1859705332c6.png",
          "https://minio.xjiot.link/93album/dbc2973b-bde3-4d84-84c6-294da13708e5.png",
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.my-layout{
  position: relative;
  background-color: #ffffff;
}
.box1{
  width: 100%;
  height: 768px;
  padding: 120px 20px;
  min-width: calc(100vw - 484px);
  text-align: left;
  position: relative;
  background-color: transparent;
  background-image: url("https://minio.xjiot.link/93album/31b1e1674da64b749f089dcedbe05748.png");
  background-size: 60vw 636px;
  background-repeat: no-repeat;
  background-position: right bottom;

  &-left{
    max-width: calc(100vw - 484px);
    margin: 0 auto;
    position: relative;
    z-index: 1;
    &-title{
      font-weight: bold;
      font-size: 40px;
      color: #000000;
      line-height: 56px;
    }
    &-subtitle{
      margin-top: 46px;
      font-weight: 500;
      font-size: 27px;
      color: #333333;
      line-height: 34px;
    }
    &-content{
      max-width: 679px;
      min-width: 40vw;
      margin-top: 40px;
      font-weight: 400;
      font-size: 17px;
      color: #333333;
      line-height: 31px;
    }
    &-btn{
      width: 140px;
      height: 46px;
      padding: 12px 18px;
      background: #E22222;
    }
  }

}

.box2{
  border-top: 1px solid transparent;
  background-color: #F8F9FF;

  &-frame{
    max-width: calc(100vw - 484px);
    margin: 0 auto;
    .title{
      text-align: left;
      margin-top: 60px;
      margin-left: 20px;
      font-weight: bold;
      font-size: 40px;
      color: #000000;
      line-height: 56px;
    }
    .content{
      width: 100%;
      margin-top: 66px;
      display: flex;
      justify-content: space-between;
      &-item{
        height: 328px;
        text-align: left;
        background-color: #ffffff;
        margin: 0 15px 30px 15px;

        &__header{
          width: 100%;
          height: 176px;
        }
        &__body{
          width: 100%;
          height: 152px;
          padding: 20px 23px;
          &-title{
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            line-height: 25px;
          }
          &-content{
            margin-top: 15px;
            max-width: 291px;
            height: 80px;
            padding: 8px 11px;
            background: rgba(169, 169, 169,.06);
            border-radius: 2px;
          }
        }
      }
    }
  }

}


.box3{
  width: 100%;
  background-image: url("https://minio.xjiot.link/93album/f011ba08-e960-4131-9359-2a10d6723dbc.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  &-left{
    height: 521px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 56px;
  }
  &-right{
    height: 521px;
    width: 100%;
    //padding: 112px 153px;
    //padding: 112px 0 112px 0;
    padding: 112px 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    div:nth-child(n+3){
      margin-top: 31px
    }
    &-item{
      width: 100%;
      max-width: 80%;
      height: 133px;
      padding: 27px 20px;
      border: 1px solid #FFFFFF;
      text-align: left;
      &__title{
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 25px;
      }
      &__content{
        margin-top: 12px;
        font-weight: 400;
        font-size: 14px;
        color: #BBC7D8;
        line-height: 22px;
      }
    }
    &-item:hover{
      background-color: #ffffff;
      .box3-right-item__title{
        color: #333333;
      }
      .box3-right-item__content{
        color: #687990;
      }
    }
  }
}

.box4{
  position: relative;
  z-index: 1;
  width: 100%;
  height: 555px;
  &-title{
    padding-top: 60px;
    font-weight: bold;
    font-size: 40px;
    color: #000000;
    line-height: 56px;
  }
  &-content{
    &-item{
      width: 100%;
      height: 328px;
      text-align: left;
      &__header{
        width: 100%;
        height: 204px;
        background-size: cover;
      }
      &__body{
        margin-top: 17px;
        width: 100%;
        height: 152px;
        &-title{
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 25px;
        }
        &-content{
          margin-top: 9px;
          max-width: 454px;
          height: 80px;
          border-radius: 2px;
        }
      }
    }
  }
}

.box5{
  position: relative;
  z-index: 1;
  height: 605px;
  border-top: 1px solid transparent;

  .warp{
    max-width: calc(100vw - 484px);
    margin: 0 auto;
  }
  .title{
    text-align: left;
  }

  .content{
    display: flex;
    flex-direction: row;
    &-item{
      position: relative;
      margin: 0 auto 60px auto;
      height: 153px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;

      &__line{
        position: absolute;
        left: 0;
        width: 1px;
        height: 80%;
        background-color: transparent;
      }

      img{
        width: 200px;
        height: 104px;
      }
    }


  }
}

.bottom-bg{
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 862px;
  background-size: cover;
  background: url("https://minio.xjiot.link/93album/7353b766135d42cab4c0d3c982a82477.png");
}

.red-btns{
  margin-top: 47px;
  display: flex;
  flex-direction: row;
  &-item{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #EF0B0B;
    opacity: .17;
  }
  &-item+&-item{
    margin-left: 10px
  }
  .active{
    background-color: #FE0000;
    opacity: 1;
  }
}
</style>
