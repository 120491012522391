<template>
  <a-layout id="components-layout-demo-top" class="layout">
    <Header/>
    <a-layout-content>
      <div class="layout-content">
        <router-view></router-view>
      </div>
    </a-layout-content>
    <Footer/>
  </a-layout>
</template>

<script>
import Header from "./header"
import Footer from "./footer"

export default {
  name: "FrameIndex",
  components: {Header,Footer}
}
</script>

<style lang="scss" scoped>
.layout{
  width: 100vw;
  min-height: 100vh;
}
</style>
