var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top",style:({height: _vm.$route.name==='Home'?'840px':'702px'})},[_c('div',{staticClass:"top-bg",style:({backgroundImage: `url('https://minio.xjiot.link/93album/${_vm.topBG}'`})}),_c('div',{staticClass:"top-mask"}),_c('div',{staticClass:"header",style:({color: _vm.scrollY>0?'#333333':'#ffffff'})},[_c('div',{staticClass:"header-bg",style:({
        backgroundColor: _vm.scrollY>0 ? '#ffffff' : _vm.headerBG,
        opacity: _vm.scrollY>0 ? 1 : .3,
        boxShadow: _vm.scrollY>0 ? 'rgba(0, 0, 0, 0.05) 0px 10px 60px 0px' : 'unset'
      })}),_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"logo",style:({
          backgroundImage: _vm.$route.name!=='Home' && _vm.scrollY===0
          ?`url('https://minio.xjiot.link/93album/bad8615a35254db88f4af41ae45b1922.png')`
          :`url('https://minio.xjiot.link/93album/2935b8f3dba048289a9454eee7f11b7d.png')`})}),_c('div',{staticClass:"menu"},_vm._l((_vm.menus),function(item){return _c('div',{key:item.name,class:['menu-item', _vm.activeMenu===item.name?'active':''],on:{"click":function($event){return _vm.jump(item)},"mouseenter":function($event){return _vm.handleMouseenter(item)},"mouseleave":function($event){return _vm.handleMouseout(item)}}},[_c('div',{staticClass:"menu-item__title"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)])]),(_vm.pageTitles[_vm.$route.name])?_c('div',{staticClass:"title"},[_c('div',{staticClass:"title-cn"},[_c('div',{staticClass:"title-cn-l"},[_vm._v(_vm._s(_vm.pageTitles[_vm.$route.name].cn_l))]),_c('div',{staticClass:"title-cn-r"},[_vm._v(_vm._s(_vm.pageTitles[_vm.$route.name].cn_r))])]),_c('div',{staticClass:"title-en"},[_vm._v(_vm._s(_vm.pageTitles[_vm.$route.name].en))])]):_vm._e(),(_vm.$route.name==='Home')?_c('div',{staticClass:"home-title"},[_vm._m(0),_c('div',{staticClass:"home-title-feature feature"},[_vm._v(" 智慧城市 ｜智慧政府 ｜ 数字医疗 ｜企业数字化转型 ")]),_c('div',{staticClass:"home-title-btn",on:{"click":_vm.handleConsult}},[_vm._v("立刻咨询")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submenuShow),expression:"submenuShow"}],staticClass:"submenu",style:({top: _vm.scrollY+90 + 'px'}),on:{"mouseenter":_vm.handleMouseenter,"mouseleave":_vm.handleMouseout}},_vm._l((Object.keys(_vm.submenus)),function(item){return _c('div',{key:item.name,staticClass:"submenu-item"},[_c('div',{staticClass:"submenu-item-header"},[_vm._v(_vm._s(_vm.submenus[item].title))]),_c('div',{staticClass:"submenu-item-body"},[_c('div',{staticClass:"submenu-item-body__menu"},_vm._l((_vm.submenus[item].children),function(menu){return _c('div',{key:menu.name,staticClass:"submenu-item-body__menu-item",on:{"click":function($event){return _vm.jump(menu)}}},[_vm._v(" "+_vm._s(menu.title)+" ")])}),0)])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-title-text"},[_vm._v("为行业客户提供领先"),_c('br'),_vm._v("数字化解决方案")])
}]

export { render, staticRenderFns }