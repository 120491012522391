<template>
  <div class="my-layout">
    <div class="box box1">
      <div class="title">企业介绍</div>
      <div class="content">
        <a-row :gutter="38">
          <a-col :span="14">
            <div class="content-left"></div>
          </a-col>
          <a-col :span="10">
            <div class="content-right">
              <div>
                新疆智翔科技有限公司成立于2010年，注册资金2000万元，是国家级高新技术企业，自治区级“专精特新”企业，乌鲁木齐物联网工程技术研究中心，目前拥有国家级和自治区级涉密信息系统资质以及各类知识产权50余项，先后获得国家科技部和自治区科技厅中小企业创新基金立项，获得自治区经信委电子发展专项和自治区重点新技术的资金支持。紧紧围绕新疆会议精神和数字中国战略，结合企业自主创新的发展战略，以云计算、人工智能、区块链、5G融合等技术在数字政务、数字医疗等方面的研发应用为发展方向，拥有一支近60人的产品研发和技术实施骨干团队。
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="box box2">
      <div class="title">发展历程</div>
      <div class="box2-content">
        <div class="box2-content-frame">
          <div class="precess" v-for="item1 in box2Content" :key="item1.title">
            <img class="precess-year" :src="`https://minio.xjiot.link/93album/${item1.img}`" alt="" />
            <div class="precess-point">
              <div class="precess-point-white"></div>
            </div>
            <div class="precess-content">
              <div class="precess-content-line"></div>
              <div class="precess-content-item" v-for="(item11, item11Index) in item1.content" :key="item11Index">
                <div class="precess-content-item-line" v-if="item11Index>0"></div>
                <div class="precess-content-item-circle"></div>
                <div class="precess-content-item-text">{{ item11 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box2-content-frame">
          <div class="precess" v-for="item2 in box2Content" :key="item2.title">
            <img class="precess-year" :src="`https://minio.xjiot.link/93album/${item2.img}`" alt="" />
            <div class="precess-point">
              <div class="precess-point-white"></div>
            </div>
            <div class="precess-content">
              <div class="precess-content-line"></div>
              <div class="precess-content-item" v-for="(item21, item21Index) in item2.content" :key="item21Index">
                <div class="precess-content-item-line" v-if="item21Index>0"></div>
                <div class="precess-content-item-circle"></div>
                <div class="precess-content-item-text">{{ item21 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="box box3">
      <div class="title">企业文化</div>
      <div class="content">
        <div class="model" v-for="item in box3Content" :key="item.title">
          <div class="model-title">{{ item.title }}</div>
          <div class="model-content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="box box4">
      <div class="title">企业荣誉</div>
      <div class="content">
        <VueSlickCarousel v-bind="settings">
          <div v-for="(item, index) in box4Content" :key="index">
            <div class="honor">
              <img :src="item.url" alt="" @click="openPreviewImg(item.url)">
              <p>{{item.content}}</p>
            </div>
          </div>
          <template #customPaging="page">
            <div class="custom-dot">
              {{ page }}
            </div>
          </template>
        </VueSlickCarousel>
      </div>
    </div>
    <PreviewImage
        :visible.sync="previewImg.visible"
        :img-url="previewImg.imgUrl"
    />
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import PreviewImage from "@/components/PreviewImage/index"

export default {
  name: "FrameAbout",
  components: {VueSlickCarousel, PreviewImage},
  data() {
    return {
      previewImg:{
        visible: false,
        imgUrl:""
      },
      box2Content: [
        {
          title: "2023",
          img: "8b356257a8744d34b9b0e33e7dfaafe4.png",
          content: ["数字医疗业务体系成型", "参与多个自治区重大专项“数字骨科”项目"]
        },
        {
          title: "2022",
          img: "2807c3ca95484e409ae0b33a141e5d98.png",
          content: ["取得软件成熟度CMMI3级认定", "获批自治区重大专利“数字骨科”项目"]
        },
        {
          title: "2021",
          img: "3a941acfed344a8ca4ce9147ec9b8542.png",
          content: ["荣获“自治区专精特新企业”", "智慧防疫平台“任方圆”上线"]
        },
        {
          title: "2020",
          img: "1be1737b441f416092cffef61fead2e8.png",
          content: ["智慧社区“社区微邻里”诞生"]
        },
        {
          title: "2019",
          img: "8a1e487dc90e45c79d6a4b704cec5848.png",
          content: ["荣获“中国地区卓越IT服务商”称号"]
        },
        {
          title: "2018",
          img: "3ee44e67fd59493eba07b82bbb83534a.png",
          content: ["汇聚平台占领全市1/2市场份额"]
        },
        {
          title: "2017",
          img: "63c471fb38c340f5aed240f914fd5ab3.png",
          content: ["成立中共智翔科技党支部"]
        },
        {
          title: "2016",
          img: "7d11a140221e4421ad3be495e3f42347.png",
          content: ["自主研发第二代智能云门禁系统"]
        },
        {
          title: "2015",
          img: "3f21c3bfb92f464f888f2c573c97f03d.png",
          content: ["获得自治区计算机系统集成甲级资质"]
        },
        {
          title: "2014",
          img: "189546c845cc4c5ca3020aaecfec3a23.png",
          content: ["自主研发物联网数据终端"]
        },
        {
          title: "2013",
          img: "83e32a5430a940e8bc134661c54284b9.png",
          content: ["获得国家高新技术企业认证"]
        },
        {
          title: "2012",
          img: "c717abd225c84d6b9e286ec02d0ebb53.png",
          content: ["荣获自治区重点新技术企业"]
        },
        {
          title: "2011",
          img: "6d869b6c464d4ad5b7b0220efbc4b042.png",
          content: ["获得国家科技部中小企业创新基金"]
        },
        {
          title: "2010",
          img: "61c88db21c744018b2d5c6c93ea6c9d5.png",
          content: ["公司注册成立"]
        },
      ],
      box3Content: [
        {
          title: "企业愿景",
          content: "成为最优秀的数字化服务商"
        },
        {
          title: "企业使命",
          content: "用科技为客户连接温暖的服务"
        },
        {
          title: "服务目标",
          content: "提升数字化治理能力，提高数字化服务水平"
        },
        {
          title: "宣传口号",
          content: "智翔科技-数字化服务商"
        }
      ],
      settings:{
        "autoplay": true,
        "autoplaySpeed": 3000,
        "speed": 1500,
        "dots": true,
        "arrows": false,
        "focusOnSelect": true,
        "pauseOnDotsHover": true,
        "pauseOnFocus": true,
        "pauseOnHover": true,
        "infinite": true,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "touchThreshold": 5,
      },
      box4Content: [
        {
          url: "about/image1.png",
          content: '新疆智翔科技有限公司成立至今连续四次通过国家级高新技术企业认证，体现了公司在自主知识产权，科技成果转化能力，研究开发管理水平和人才结构等方面取得了权威性认可。'
        },
        {
          url: "about/image11.png",
          content: '计算机信息系统集成（信息工程）资质由新疆IT三会评审、颁发，用于证明企业从事信息系统集成及服务业务的综合能力和水平，包括经营业绩、财务状况、信誉、管理能力、技术实力和人才实力等要素。该项资质的取得标志着我公司市场竞争能力和综合实力又一次得到提升！'
        },
        {
          url: "about/image2.png",
          content: '新疆智翔科技有限公司作为自治区科技型企业，大力实施知识产权战略，推进产学研结合，不断增强公司技术创新能力。被评为自治区“专精特新”企业，是对公司创新发展成就的肯定。'
        },
        {
          url: "about/image3.png",
          content: '新疆智翔科技有限公司通过ITSS信息技术服务运行维护标准符合性认证。提高企业运维业务的附加值，增进企业业务更强更多元化发展。'
        },
        {
          url: "about/image4.png",
          content: 'ISO9001是国际标准化组织制定的质量管理体系标准，新疆智翔科技有限公司正式取得ISO9001质量管理体系认证证书，体现了智翔科技标准化、规范化、科学化的质量管理水平。'
        },
        {
          url: "about/image5.png",
          content: 'ISO27001作为全球公认的最权威的信息安全管理标准，标志着新疆智翔科技有限公司在信息安全管理生产上要求更加严格，品质更有保障，公司更具竞争力。'
        },
        {
          url: "about/image6.png",
          content: '新疆智翔科技有限公司全面落实知识产权战略精神，积极应对知识产权竞争态势，有效提高知识产权对企业经营发展的贡献水平。'
        },
        {
          url: "about/image7.png",
          content: '新疆智翔科技有限公司管理规范，设备、相关技术人员齐备，符合安防资质申请要求，经审查、评定公司的安全技术防范行业安防工程企业资质等级为贰级。'
        },
        {
          url: "about/image8.png",
          content: '根据新疆软件行业协会发布的《软件企业评估名单》和《软件产品评估名单》文件，新疆智翔科技有限公司顺利通过软件企业证书和软件产品证书，获得双软认证。'
        },
        {
          url: "about/image9.png",
          content: '根据新疆软件行业协会发布的《软件企业评估名单》和《软件产品评估名单》文件，新疆智翔科技有限公司顺利通过软件企业证书和软件产品证书，获得双软认证。'
        },
        {
          url: "about/image10.png",
          content: '新疆智翔科技有限公司顺利通过国际CMMI3资质认证，标志着在软件研发、项目管理、解决方案交付等方面的实施规范流程成熟完整，已达到国际技术标准和管理要求的水平。'
        }
      ]
    }
  },
  methods:{
    // 打开预览图片遮罩层
    openPreviewImg(imgUrl) {
      this.previewImg.imgUrl = imgUrl
      this.previewImg.visible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.my-layout{
  max-width: 100vw;
  overflow: hidden;
}


.box1 {
  height: 708px;
  background-color: #F9F9F9;

  .content {
    width: 100%;
    height: 351px;

    &-left {
      width: 100%;
      height: 351px;
      background-image: url("https://minio.xjiot.link/93album/photo-1508385082359-f38ae991e8f2.png");
      background-size: cover;
    }

    &-right {
      height: 351px;
      background-color: #ffffff;
      //padding: 50px 58px;
      padding: 0 20px;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 36px;
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;
      div{
        text-align: justify;
      }
    }
  }
}

.box2 {
  height: 780px;
  position: relative;
  background-image: url("https://minio.xjiot.link/93album/fd5543c2-c68f-4ebd-b993-a34a65b9dab7.png");
  background-size: cover;

  .title {
    color: #ffffff;
    padding-bottom: 40px;
  }

  &-content {
    height: 372px;
    position: relative;
    display: flex;
    overflow: hidden;
    &-frame{
      display: flex;
      animation: Animation 30s linear infinite;
    }
  }


  @keyframes Animation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-350vw);
    }

  }

  .line {
    position: absolute;
    top: 314px;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
  }

  .precess {
    display: flex;
    flex-direction: column;
    margin-right: 80px;
    max-width: calc(25vw - 80px);
    min-width: 387px;
    //min-width: calc(25vw - 80px);
    //display: inline-block;
    //margin-right: 80px;
    min-height: 278px;
    text-align: left;

    &-year {
      width: 85px;
      //height: 60px;
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 19px;
      font-size: 50px;
      color: #FFFFFF;
      line-height: 60px;
    }

    &-point {
      position: relative;
      z-index: 101;
      margin-left: 31px;
      margin-top: 30px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
      padding: 14px;

      &-white {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }

    &-content {
      position: relative;
      margin-top: 40px;
      background-color: #ffffff;
      box-shadow: 0px 11px 30px 0px rgba(22, 3, 2, 0.09);
      border-radius: 6px;
      padding: 41px 23px 36px 45px;

      &-line{
        width:2.5px;
        height: 110px;
        position: absolute;
        z-index: 100;
        left: 54px;
        top: -40px;
        background-color: #FE1100;
      }

      &-item {
        display: flex;
        justify-content: left;
        align-items: center;
        position: relative;
        &-line{
          position: absolute;
          z-index: 90;
          width:2.5px;
          height: 70px;
          left: 9px;
          top: -43px;
          background-color: #FE1100;
        }
        &-circle {
          position: relative;
          z-index: 101;
          width: 20px;
          height: 20px;
          border-radius: 10px;
          border: 3px solid #FE1100;
          background-color: #ffffff;
        }

        &-text {
          margin-left: 16px;
          font-weight: 400;
          font-size: 18px;
          color: #000000;
          line-height: 36px;
        }
      }


      .precess-content-item + .precess-content-item {
        margin-top: 33px;
      }
    }
  }
}

.box3 {
  height: 496px;
  background-image: url("https://minio.xjiot.link/93album/ab70d6db80be43edbdbb4471bf84c367.png");
  background-size: cover;

  .title {
    color: #ffffff;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .model {
    position: relative;
    width: 306px;
    height: 133px;
    padding: 27px 20px;
    background: rgba(255, 255, 255, 0.27);
    border-radius: 2px;
    border: 1px solid #FFFFFF;
    text-align: left;

    &-title {
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 25px;
    }

    &-content {
      margin-top: 12px;
      font-weight: 400;
      font-size: 14px;
      color: #BBC7D8;
      line-height: 22px;
    }
  }

  .model:hover {
    background-color: #ffffff;

    .model-title {
      color: #333333;
    }

    .model-content {
      color: #687990;
    }
  }

  .model + .model {
    margin-left: 60px;
  }
}

.box4{
  height: 972px;
  .content{
    height: 564px;
    position: relative;

    .slick-active{
      margin-top: 20px;
      .custom-dot{
        color: #ed1e24;
        background-color: #ed1e24;
      }
    }
    .custom-dot{
      color: #ababab;
      background-color: #ababab;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      font-size: 0;
    }

    .honor{
      //margin: 0 auto;
      margin: 0 auto 40px auto;
      width: 80%;
      height: 564px;
      padding: 60px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 1px 1.732px 16px 0px rgba(0, 0, 0, 0.07);
      border-radius: 6px;

      p{
        margin-top: 20px;
        text-align: justify;
      }
    }
  }

}
</style>
