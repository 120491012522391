<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import {emitter} from "@/plugins/emitter";

export default {
  name: 'App',
  methods:{
    handleScroll(){
      emitter.emit("customScrollY", window.scrollY)
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss">
@import "@/style/common.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 100vw;
  overflow: hidden;
}
</style>
