<template>
  <div class="my-layout">
    <div class="box">
      <div class="box-title">告诉我们你的项目需求</div>
      <div class="box-subtitle">请填写如下表格并提交，我们将尽快通过各种方式与您联系。谢谢！</div>
      <div class="box-form form">
        <div class="form-item">
          <div class="form-item__title"><span style="color: red">*</span> 姓名</div>
          <div class="form-item__input">
            <a-input v-model="form.name" placeholder="请输入您的姓名" />
          </div>
        </div>
        <div class="form-item">
          <div class="form-item__title">公司</div>
          <div class="form-item__input">
            <a-input v-model="form.company" placeholder="请输入您的公司名称" />
          </div>
        </div>
        <div class="form-item">
          <div class="form-item__title"><span style="color: red">*</span> 联系电话</div>
          <div class="form-item__input">
            <a-input v-model="form.phone" placeholder="请输入您的联系电话" />
          </div>
        </div>
        <div class="form-item">
          <div class="form-item__title">邮箱</div>
          <div class="form-item__input">
            <a-input v-model="form.email" placeholder="请输入您的邮箱" />
          </div>
        </div>
        <div class="form-item" style="width: 100%;height: 135px;align-items: flex-start;">
          <div class="form-item__title"><span style="color: red">*</span> 项目需求</div>
          <div class="form-item__input" style="width: calc(100% - 120px)">
            <a-input v-model="form.projectRequirement" type="textarea" placeholder="请填写您的项目需求"  :auto-size="{ minRows: 5, maxRows: 5 }"/>
          </div>
        </div>
        <div class="btn" @click="submit">提交信息</div>
      </div>
    </div>
    <div class="contact">
      <div class="contact-title">联系方式</div>
      <div class="contact-content">
        <div>电话 0991-4633991</div>
        <div>邮箱 xzb@xjiot.com</div>
      </div>
    </div>
    <div class="address">
      <div class="address-title">办公地址</div>
      <div class="address-content">
        <div style="display: flex;align-items: center">
          <img class="address-content-icon" src="https://minio.xjiot.link/93album/a3656963-d6fc-4a72-90df-09662a9baa06.png" alt=""/>
          <div style="margin-left: 15px">新疆乌鲁木齐</div>
        </div>
        <div>新疆乌鲁木齐市水磨沟区南湖东路95号2栋</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrameContact",
  data(){
    return{
      form:{
        company:"",
        email: "",
        name: "",
        phone: "",
        projectRequirement: ""
      }
    }
  },
  methods:{
    submit(){
      if(!this.form.name){
        this.$notification['error']({
          message: '提交失败！',
          top: '120px',
          description:
              '请填写您的姓名。',
        });
      }
      if(!this.form.phone){
        this.$notification['error']({
          message: '提交失败！',
          top: '120px',
          description:
              '请填写您的联系电话。',
        });
      }
      if(!this.form.projectRequirement){
        this.$notification['error']({
          message: '提交失败！',
          top: '120px',
          description:
              '请填写您的项目需求。',
        });
      }
      if(!this.form.name||!this.form.projectRequirement||!this.form.phone){
        return
      }
      let myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')
      const request = new Request("http://sapi.xjiot.link/jeecg-boot/apitest/zxGwProjectRequirement/add",{
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(this.form)
      })
      fetch(request).then(res=>{
        console.log(res)
        if(res.status === 200){
          this.$notification['success']({
            message: '提交成功！',
            top: '120px',
            description:
                '我们稍后会安排工作人员与您联系。',
          });
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my-layout{
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
}
.box{
  padding: 120px 0;
  width: calc(100vw - 484px);
  margin: 0 auto;
  &-title{
    font-weight: bold;
    font-size: 40px;
    color: #000000;
    line-height: 56px;
    text-align: left;
  }
  &-subtitle{
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    color: #8E8E8E;
    line-height: 24px;
    text-align: left;

  }
  &-form{
    margin-top: 60px;
    width: 100%;
    height: 598px;
    padding: 60px;
    background-color: #ffffff;
  }
  .form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item{
      &+&{
        margin-top: 60px;
      }
      width: calc(50% - 66px);
      height: 58px;
      border-bottom: 1px solid #C7C7C7;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &__title{
        font-weight: 500;
        font-size: 18px;
        color: #9B9B9B;
        line-height: 34px;
        text-align: left;
      }
      &__input{
        width: calc(100% - 120px);
        margin-left: 10px;
        ::v-deep input{
          width: 100%;
          border: unset!important;
        }
        ::v-deep textarea{
          border: unset!important;
        }
      }
    }
    .btn{
      margin-top: 60px;
      width: 140px;
      height: 47px;
      background: #E22222;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      font-weight: 400;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 22px;
    }
  }
}

.contact{
  width: 100%;
  height: 389px;
  padding: 120px 0;
  background-image: url("https://minio.xjiot.link/93album/f65cea50-3f55-466f-bc84-b93b2cd17d59.png");
  background-size: cover;
  background-color: #DE4342;
  box-shadow: 0px 6px 14px 0px rgba(205,205,205,0.26);
  &-title{
    font-weight: bold;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 56px;
    text-align: center;
  }
  &-content{
    margin-top: 60px;
    display: flex;
    justify-content: space-around;

    font-weight: 500;
    font-size: 22px;
    color: #FFFFFF;
    line-height: 33px;
  }
}

.address{
  width: 100%;
  height: 508px;
  padding: 120px 0;
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url("https://minio.xjiot.link/93album/0b97ae01-38c1-4fc3-9d4a-e2594b3ee10c.png");


  &-title{
    font-weight: bold;
    font-size: 40px;
    color: #000000;
    line-height: 56px;
  }
  &-content{
    margin-top: 60px;
    width: 600px;
    height: 152px;
    padding: 27px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0px 6px 14px 0px rgba(205,205,205,0.26);
    &-icon{
      width: 34px;
      height: 40px;
      padding-bottom: 4px;
      border-bottom: 2px solid #FE1100;
    }
    div{
      font-weight: 500;
      font-size: 19px;
      color: #383838;
      line-height: 28px;
    }
  }
}

</style>
