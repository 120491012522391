<template>
  <div class="footer-bg">
    <div class="copyright-wrap" style="background: #000;">
      <div class="">
        <a-row type="flex" align="middle"  style="margin: 0;">
          <a-col :span="4"  style="padding: 0 3%;">
            <img src="@/assets/f_logo.png" alt="img" width="150px">
          </a-col>
          <a-col :span="6">Copyright©2017 新疆智翔科技有限公司</a-col>
          <a-col :span="4">ICP备案：新ICP12002153号-6 </a-col>
          <a-col :span="6">公安备案号：新公网安备 65010502000114号</a-col>
          <a-col :span="4" style="padding: 5px 5%;"><img src="@/assets/zxkj.jpg" alt="img" width="80px"></a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "FrameFooter"
}
</script>

<style lang="scss" scoped>
.footer-bg {
  color:#777;
  background-position: center 0 !important;

}
.copyright-wrap {
  background-image: -moz-linear-gradient( 0deg, rgb(237,30,36) 0%, rgb(237,80,27) 100%);
  background-image: -webkit-linear-gradient( 0deg, rgb(237,30,36) 0%, rgb(237,80,27) 100%);
  background-image: -ms-linear-gradient( 0deg, rgb(237,30,36) 0%, rgb(237,80,27) 100%);
  padding:15px 0;
  color: #fff;
  font-size: 15px;
  position: relative;
  z-index: 2;
}

</style>
