<template>
	<div class="top" :style="{height: $route.name==='Home'?'840px':'702px'}">
		<div class="top-bg" :style="{backgroundImage: `url('https://minio.xjiot.link/93album/${topBG}'`}"></div>
		<div class="top-mask"></div>
		<div class="header" :style="{color: scrollY>0?'#333333':'#ffffff'}">
			<div class="header-bg" :style="{
        backgroundColor: scrollY>0 ? '#ffffff' : headerBG,
        opacity: scrollY>0 ? 1 : .3,
        boxShadow: scrollY>0 ? 'rgba(0, 0, 0, 0.05) 0px 10px 60px 0px' : 'unset'
      }"></div>
			<div class="header-content">
				<div class="logo" :style="{
          backgroundImage: $route.name!=='Home' && scrollY===0
          ?`url('https://minio.xjiot.link/93album/bad8615a35254db88f4af41ae45b1922.png')`
          :`url('https://minio.xjiot.link/93album/2935b8f3dba048289a9454eee7f11b7d.png')`}"></div>
				<div class="menu">
					<div v-for="item in menus" :class="['menu-item', activeMenu===item.name?'active':'']"
						:key="item.name" @click="jump(item)" @mouseenter="handleMouseenter(item)"
						@mouseleave="handleMouseout(item)">
						<div class="menu-item__title">
							{{ item.title }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="title" v-if="pageTitles[$route.name]">
			<div class="title-cn">
				<div class="title-cn-l">{{ pageTitles[$route.name].cn_l }}</div>
				<div class="title-cn-r">{{ pageTitles[$route.name].cn_r }}</div>
			</div>
			<div class="title-en">{{ pageTitles[$route.name].en }}</div>
		</div>

		<div class="home-title" v-if="$route.name==='Home'">
			<div class="home-title-text">为行业客户提供领先<br>数字化解决方案</div>
			<div class="home-title-feature feature">
				智慧城市 ｜智慧政府 ｜ 数字医疗 ｜企业数字化转型
			</div>
			<div class="home-title-btn" @click="handleConsult">立刻咨询</div>
		</div>

		<div v-show="submenuShow" class="submenu" :style="{top: scrollY+90 + 'px'}" @mouseenter="handleMouseenter"
			@mouseleave="handleMouseout">
			<div class="submenu-item" v-for="item in Object.keys(submenus)" :key="item.name">
				<div class="submenu-item-header">{{ submenus[item].title }}</div>
				<div class="submenu-item-body">
					<div class="submenu-item-body__menu">
						<div class="submenu-item-body__menu-item" v-for="menu in submenus[item].children"
							:key="menu.name" @click="jump(menu)">
							{{ menu.title }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		emitter
	} from "@/plugins/emitter";

	export default {
		name: "FrameHeader",
		data() {
			return {
				scrollY: 0,
				menus: [{
						name: "Home",
						title: "首页",
						path: "/frame/home"
					},
					{
						name: "Product",
						title: "产品介绍",
						path: "/frame/product"
					},
					{
						name: "About",
						title: "关于我们",
						path: "/frame/about"
					},
					{
						name: "Contact",
						title: "联系我们",
						path: "/frame/contact"
					},
				],
				// 页面标题
				pageTitles: {
					Product00: {
						cn_l: "医保智能规则",
						cn_r: "提醒系统",
						en: "Medical insurance intelligent rule reminder system"
					},
					Product01: {
						cn_l: "医院综合收费",
						cn_r: "智能管理核准系统",
						en: "Hospital Comprehensive Charging Intelligent Management Approval System"
					},
					Product02: {
						cn_l: "医保基金监管",
						cn_r: "智能审核系统",
						en: "Medical insurance fund management function review system"
					},
					Product03: {
						cn_l: "医保智能规则",
						cn_r: "提醒系统",
						en: "Medical insurance intelligent rule reminder system"
					},
					Product04: {
						cn_l: "新医云",
						cn_r: "·云影像",
						en: "Xin Yi Yun · cloud image"
					},
					Product05: {
						cn_l: "医疗",
						cn_r: "科研项目",
						en: "Medical research projects"
					},
					Product10: {
						cn_l: "技术",
						cn_r: "运维服务",
						en: "Technical operation and maintenance services"
					},
					Product11: {
						cn_l: "智慧",
						cn_r: "社区",
						en: "Intelligent community"
					},
					Product12: {
						cn_l: "智慧",
						cn_r: "监督",
						en: "Intelligent supervision"
					},
					About: {
						cn_l: "关于",
						cn_r: "我们",
						en: "About Us"
					},
					Contact: {
						cn_l: "联系",
						cn_r: "我们",
						en: "Contact Us"
					}
				},
				// 产品介绍子菜单
				headerBG: "transparent",
				submenuShow: false,
				mouseOutController: null,
				submenus: [{
						title: "数字医疗",
						name: "product0",
						path: "",
						children: [{
								father: "Product",
								name: 'Product00',
								title: "医保智能规则提醒系统",
								path: "/frame/product/product00"
							},
							{
								father: "Product",
								name: 'Product01',
								title: "医院综合收费智能管理核准系统",
								path: "/frame/product/product01"
							},
							{
								father: "Product",
								name: 'Product02',
								title: "医保基金监管智能审核系统",
								path: "/frame/product/product02"
							},
							// {
							//   father: "Product",
							//   name: 'Product03',
							//   title: "临床科研大数据平台备份",
							//   path: "/frame/product/product03"
							// },
							{
								father: "Product",
								name: 'Product04',
								title: "新医云·云影像",
								path: "/frame/product/product04"
							},
							{
								father: "Product",
								name: 'Product05',
								title: "医疗科研项目",
								path: "/frame/product/product05"
							}
						]
					},
					{
						title: "数字政务",
						name: "Product1",
						path: "",
						children: [{
								father: "Product",
								name: 'Product10',
								title: "技术运维服务",
								path: "/frame/product/product10"
							},
							{
								father: "Product",
								name: 'Product11',
								title: "智慧社区",
								path: "/frame/product/product11"
							},
							{
								father: "Product",
								name: "Product12",
								title: "智慧监督",
								path: "/frame/product/product12"
							}
						]
					}
				],
			}
		},
		computed: {
			topBG() {
				switch (this.$route.name) {
					case "Home":
						return "60315a67-7eea-4e0b-842b-c1fba21fa7d0.png";
					case "Contact":
						return "4689c7b6-d390-4c6f-95b3-fd4b67ec5fd0.png";
					case "About":
						return "48de611f-f946-4c05-83f0-4ab7d38092ea.png";
					case "Product00":
						return "412d76a9-c707-45a3-aff5-9b3c6bc434be.png";
					case "Product01":
						return "bcde272b-1a70-4a13-bc3f-fe34fecbe57c.png";
					case "Product02":
						return "af1c986c-0ddb-42d0-afe0-3e6743989dec.png";
					case "Product04":
						return "13a15679-e67c-49e6-a3b5-00de128059af.png";
					case "Product05":
						return "a6750da4-efbc-4ea8-b6f6-5b58f9b78d99.png";
					case "Product10":
						return "2b134643-0488-4ec6-b019-b8b197b7b6a7.png";
					case "Product11":
						return "0c291cc5-cf81-47a4-8042-6013dd706f7a.png";
					case "Product12":
						return "5a153f06e23f4a6ba2ebcfc7ad30ece7.png";
				}
				return '60315a67-7eea-4e0b-842b-c1fba21fa7d0.png'
			},
			activeMenu() {
				switch (this.$route.name) {
					case "Home":
						return "Home";
					case "About":
						return "About";
					case "Contact":
						return "Contact";
					default:
						return "Product";
				}
			}
		},
		methods: {
			jump(route) {
				if (route.name === 'Product') {
					return
				}
				this.$router.push({
					path: route.path
				})
			},
			handleMouseenter(route) {
				clearTimeout(this.mouseOutController)
				setTimeout(() => {
					this.headerBG = "#FFFFFF";
					if (route.name === 'Product') {
						this.submenuShow = true
					}
				}, 0)
				// if (!route.name) {
				//   clearTimeout(this.mouseOutController)
				//   return
				// }
				// if (route.name === 'Product') {
				//   clearTimeout(this.mouseOutController)
				//   setTimeout(() => {
				//     this.headerBG = "#FFFFFF";
				//     this.submenuShow = true
				//   }, 0)
				// }
			},
			handleMouseout() {
				this.mouseOutController = setTimeout(() => {
					this.headerBG = "transparent"
					this.submenuShow = false
				}, 100)
			},
			handleConsult() {
				this.$router.push({
					path: '/frame/contact'
				})
			}
		},
		onShow() {},
		created() {
			emitter.on("customScrollY", (scrollY) => {
				console.log(scrollY)
				this.scrollY = scrollY
			})
		}
	}
</script>

<style lang="scss" scoped>
	.top {
		width: 100%;
		position: relative;

		display: flex;
		justify-content: center;
		align-items: center;

		&-bg {
			width: 100%;
			height: 100%;
			background-color: #262626;
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			background-size: cover;
			//opacity: 0.4;
		}

		&-mask {
			width: 100%;
			height: 100%;
			background-color: #262626;
			opacity: 0.4;
		}
	}

	.header {
		position: fixed;
		z-index: 99999;
		top: 0;
		left: 0;
		width: 100vw;
		height: 90px;
		margin: 0 auto;
		color: #ffffff;
		overflow: hidden;


		&-bg {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			opacity: .3;
		}

		&-content {
			max-width: calc(100vw - 484px);
			min-width: 600px;
			height: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.logo {
				width: 196px;
				height: 48px;
				background-size: contain;
				background-repeat: no-repeat;
				margin-left: -70px;
				margin-top: 26px;
			}

			.menu {
				width: 462px;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 18px;
				line-height: 24px;

				&-item {
					height: 100%;
					font-weight: 400;
					display: flex;
					align-items: center;

					&:hover {
						cursor: pointer;
					}
				}

				.active {
					color: #FE0000;
					font-weight: 550;
					border-bottom: 2px solid #FE0000;
				}
			}
		}
	}

	.submenu {
		position: absolute;
		z-index: 99;
		top: 90px;
		right: 0;
		width: 790px;
		height: 300px;
		padding: 40px 60px;
		background: #FFFFFF;
		display: flex;
		justify-content: space-around;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 60px 0px;

		&-item {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			&-header {
				font-weight: 600;
				font-size: 16px;
				color: #3E3E3E;
				line-height: 20px;
			}

			&-body {
				margin-top: 30px;

				&__menu {
					text-align: left;
					font-weight: 400;
					font-size: 14px;
					color: #6F6F6F;
					line-height: 20px;

					&-item {
						cursor: pointer;
					}

					&-item+&-item {
						margin-top: 10px;
					}

					&-item:before {
						content: "";
						width: 2px;
						height: 2px;
						display: inline-block;
						border-radius: 50%;
						background: #FE0000;
						vertical-align: middle;
						margin-left: 14px;
						margin-right: 7px;
					}
				}
			}
		}

		.submenu-item-body__menu-item:hover {
			color: #FE0000;
		}
	}

	.title {
		position: absolute;
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		&-cn {
			display: flex;
			font-weight: 500;
			font-size: 64px;
			color: #FE0000;
			line-height: 90px;
			letter-spacing: 12px;

			&-l {
				color: #FE0000;
			}

			&-r {
				color: #ffffff;
			}
		}

		&-en {
			margin-top: 15px;
			font-weight: 500;
			font-size: 24px;
			color: #FFFFFF;
			line-height: 35px;
		}
	}

	.home-title {
		position: absolute;
		z-index: 1;
		left: 16%;
		text-align: left;

		&-text {
			font-weight: 500;
			font-size: 64px;
			color: #FFFFFF;
			line-height: 90px;
		}

		&-feature {
			margin-top: 40px;
			background-color: #A90F0F;
			padding: 10px 20px;

			font-weight: 400;
			font-size: 24px;
			color: #FFFFFF;
			line-height: 34px;
		}

		&-btn {
			margin-top: 40px;
			width: 110px;
			height: 40px;
			background: rgba(255, 255, 255, 0.26);
			border-radius: 10px;
			border: 1px solid #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #FFFFFF;
			cursor: pointer;
		}
	}
</style>
